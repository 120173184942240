type ABTestGroups = {
    [key in keyof typeof AB_TEST_GROUPS]: Array<keyof typeof AB_TESTS>;
};

type ABTests = {
    [key in keyof typeof AB_TESTS]: ABTestProps & {
        gaTestName: (keyof typeof AB_TESTS);
        group?: (keyof typeof AB_TEST_GROUPS)
    }
};

export type ABTestProps = {
    gaDimension: string;
    // String uses for backward compatibility. In the future in ideal world this prop should have type only from AB_TESTS keys
    gaTestName: (keyof typeof AB_TESTS) | string;
    hardApplyParam?: string;
    unleashKey?: string;
    // The same here
    group?: (keyof typeof AB_TEST_GROUPS) | string;
};

enum AB_TEST_GROUPS {
    MaterialPageDesktopABTestsGroup = 'Group for AB tests on the material page for the desktop view'
};

enum AB_TESTS {
    PT = 'Preview Thumbnails',
    PG = 'Preview Gallery',
}

export const EXISTED_AB_TEST_GROUPS: ABTestGroups = {
    MaterialPageDesktopABTestsGroup: ['PT', 'PG'],
};

export const EXISTED_AB_TESTS: ABTests = {
    PT: {
        gaDimension: 'testSegment1',
        gaTestName: 'PT',
        unleashKey: 'PreviewThumbnails',
        group: 'MaterialPageDesktopABTestsGroup'
    },
    PG: {
        gaDimension: 'testSegment1',
        gaTestName: 'PG',
        unleashKey: 'PreviewGallery',
        group: 'MaterialPageDesktopABTestsGroup'
    }
};
